import React from "react";
import * as Styles from "./mobile-nav.styles";
import { Label } from "../../text";
import { Spacer } from "../../spacer";
import { LinkButton } from "../../button";

export const MobileNav = (props) => (
  <Styles.Wrapper mobileNavOpen={props.mobileNavOpen}>
    <Styles.NavLink to="/courses" title="Courses">
      Courses
    </Styles.NavLink>
    <Spacer height={3} />
    <Styles.NavLink to="/about-us" title="About us">
      About us
    </Styles.NavLink>
    <Spacer height={3} />
    <Styles.NavLink to="/contact" title="Contact">
      Contact
    </Styles.NavLink>
    <Spacer height={3} />
    <LinkButton to="/donate" title="Donate">
      Donate
    </LinkButton>
    <Spacer height={3} />
    <Spacer height={3} />
    <Styles.ContactWrapper>
      <Styles.ContactLink
        href="mailto:info@wvsindia.org"
        title="info@wvsindia.org"
      >
        <Label>
          Email
          <br />
          info@wvsindia.org
        </Label>
      </Styles.ContactLink>
      <Spacer height={3} />
      <Styles.ContactLink
        href="tel:+9109443699376"
        title="WVS India Chairman: Nigel Otter"
      >
        <Label>
          WVS India Chairman: Nigel Otter
          <br />
          +91 (0) 944 36 99 376
        </Label>
      </Styles.ContactLink>
      <Spacer height={3} />
      <Styles.ContactLink
        href="tel:+9109486317538"
        title="WVS ITC Clinical Director: Ilona Otter"
      >
        <Label>
          WVS ITC Clinical Director: Ilona Otter
          <br />
          +91 (0) 948 63 17 538
        </Label>
      </Styles.ContactLink>
    </Styles.ContactWrapper>
  </Styles.Wrapper>
);
